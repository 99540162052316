import React from "react";
import "../Player.css";
import { Credentials, ILiveEvent, IQuestionDto } from "../../Core/types";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import LoginDialog from "../Components/LoginDialog";
import VideoPlayer from "../Components/VideoPlayer";
import PlayerExtras from "../Components/PlayerExtras";

import * as EventService from "../../Core/Services/EventService";
import * as QuestionService from "../../Core/Services/QuestionService";
import { useLocation } from "react-router";
import Typography from "@material-ui/core/Typography";

const metaStyle = {
  color: "grey",
};

enum AuthStatus {
  ERROR,
  PENDING,
  AUTHENTICATED,
  NOT_AUTHENTICATED,
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PlayerContainer: React.FC = (): React.ReactElement => {
  const query = useQuery();
  const isReplay: string | null = query.get("Replay");
  const [meta, setMeta] = React.useState<string>("");
  const [metaAdded, setMetaAdded] = React.useState<boolean>(false);
  const [eventData, setEventData] = React.useState<{
    type: "unicastWireless" | "unicastRemote" | "multicast";
    event: ILiveEvent;
    vivohProtocol: string | null;
    needsAuthentication: boolean;
  } | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [authStatus, setAuthStatus] = React.useState<AuthStatus>(
    AuthStatus.NOT_AUTHENTICATED
  );
  const submitQuestion = (question: IQuestionDto) => {
    QuestionService.createQuestion(question).catch((err) =>
      console.log("[QUESTION/API] Create Question Failed", err.message)
    );
  };

  React.useEffect(() => {
    EventService.getLiveEvent(isReplay)
      .then((data) => {
        if (data) {
          setEventData(data);
        }
      })
      .catch((err) =>
        console.log("[EVENT/API] Get Live Event Failed", err.message)
      );
  }, [isReplay, setEventData]);

  const handleAuthenticate = (creds: Credentials) => {
    setAuthStatus(AuthStatus.PENDING);
    setError(null);
    EventService.loginToLiveEvent(creds)
      .then((result) => {
        if (result.isAuthenticated) {
          setAuthStatus(AuthStatus.AUTHENTICATED);
        } else {
          setAuthStatus(AuthStatus.NOT_AUTHENTICATED);
        }
      })
      .catch(() => {
        setAuthStatus(AuthStatus.ERROR);
        setError("Invalid Credentials");
      });
  };

  const handleVivohProtocol = React.useCallback(() => {
    if (eventData && eventData.vivohProtocol && !metaAdded) {
      const meta = document.createElement("meta");
      meta.setAttribute("http-equiv", "refresh");
      meta.setAttribute("content", `0;URL='${eventData.vivohProtocol}'`);
      document.head.appendChild(meta);
      setMeta(eventData.vivohProtocol);
      setMetaAdded(true);
    }
  }, [eventData, metaAdded]);

  const multicastPage = () => (
    <div className="no-event__wrapper">
      <div style={{ width: 350, textAlign: "center" }}>
        <Header top="" bottom="" />
        <div className="no-event__box">
          <h1
            style={{
              textDecoration: "none",
              margin: "4px 0",
            }}
            className="text-center"
          >
            Opening Vivoh Player
          </h1>
          
          <h4>Please click "Open Vivoh.exe" to start the player</h4>
          <img style={{ width: "100%" }} src="https://releases.vivoh.com/assets/open-vivoh.png"/>
        </div>
        <div className="no-event__box">
          <Typography className="underlined" variant="h4">
            Contact Us
          </Typography>
          <Typography variant="body1">
            Having problems with the webcast? Call the Service Desk at: 1(855)
            8-WE-HELP 1-855-893-4357
          </Typography>

          <Typography variant="caption">
            <a style={metaStyle} href={meta}>
              Click here to manually open the Vivoh player
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );

  const playerPage = () => {
    if (eventData && eventData.event && eventData.type) {
      const { event, type } = eventData;
      let source;
      if (!!isReplay) {
        source = event.Source.ReplayUrl;
      } else if (type === "unicastWireless") {
        source = event.Source.PrimaryUnicastUrl;
      } else if (type === "unicastRemote") {
        source = event.Source.SecondaryUnicastUrl;
      }
      return (
        <div className="player--container">
          <Header top="" bottom="" />

          <VideoPlayer
            eventIsFrame={!!event.IsFrame}
            frames={event.Frames}
            description={event.Subtitle}
            title={event.Title}
            src={source || ""}
          />

          <PlayerExtras
            event={event}
            links={event.Links || []}
            submitQuestion={submitQuestion}
          />

          <Footer />
        </div>
      );
    } else
      return (
        <div>
          <h2>Something went wrong</h2>
        </div>
      );
  };

  const noEvent = () => (
    <div className="no-event__wrapper">
      <div style={{ width: 220 }}>
        <Header top="" bottom="" />
        <div className="no-event__box">
          <h2>No Active Event</h2>
          <p>There are currently no active events. Please try again later</p>
        </div>
      </div>
      <Footer />
    </div>
  );

  if (eventData) {
    if (eventData.needsAuthentication) {
      if (authStatus === AuthStatus.AUTHENTICATED) {
        if (eventData.type === "multicast") {
          handleVivohProtocol();
          return multicastPage();
        } else {
          return playerPage();
        }
      } else {
        return (
          <LoginDialog
            authenticate={handleAuthenticate}
            error={error}
            status={authStatus}
          />
        );
      }
    } else if (eventData.type === "multicast") {
      handleVivohProtocol();
      return multicastPage();
    } else return playerPage();
  } else return noEvent();
};

export default PlayerContainer;
