import React from "react";
import "./Link.css";
import { ILinkDto } from "../../../Core/types";
import TextField from "@material-ui/core/TextField";
import { Button, Typography } from "@material-ui/core";
interface LinksFormProps {
    submit: (link: ILinkDto) => void;
    EventID: number;
}

const LinksForm: React.FC<LinksFormProps> = ({
    submit,
    EventID
}): React.ReactElement => {
    const [newLink, setNewLink] = React.useState<ILinkDto>({
        Name: "",
        URL: "",
        EventID
    });

    const fileRef = React.useRef<HTMLInputElement>(null);

    const [file, setFile] = React.useState<File | undefined>(undefined);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewLink({ ...newLink, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        if (newLink.Name.trim() && newLink.URL.trim()) {
            submit(newLink);
            setNewLink({
                Name: "",
                URL: "",
                EventID
            });
        }
    };
    const handleUpload = () => {
        setFile(undefined);
    };
    return (
        <div className="two-form__container">
            <div className="form-container mb-5">
                <Typography className="form__header" variant="h6">
                    Create a New Link
                </Typography>
                <div className="form">
                    <TextField
                        margin="dense"
                        size="small"
                        className="form-control"
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        name="Name"
                        label="Name"
                        value={newLink.Name}
                    />

                    <TextField
                        margin="dense"
                        size="small"
                        className="form-control"
                        onChange={handleChange}
                        variant="outlined"
                        type="text"
                        multiline
                        rows={3}
                        name="URL"
                        label="URL"
                        value={newLink.URL}
                    />
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    className="submit-btn"
                    onClick={handleSubmit}
                >
                    Create New Link
                </Button>
            </div>
            <div className="form-container">
                <input
                    hidden
                    style={{ display: "none" }}
                    ref={fileRef}
                    onChange={(e) => setFile(e.target.files?.[0])}
                    type="file"
                    name="file-input"
                />

                <Typography className="form__header" variant="h6">
                    Upload to Cloud
                </Typography>
                <div className="form">
                    <div className="file-form mb-4">
                        <TextField
                            type="text"
                            margin="dense"
                            size="small"
                            className="form-control"
                            variant="outlined"
                            multiline
                            name="file"
                            label="File"
                            value={file?.name}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => fileRef.current?.click()}
                        >
                            Browse
                        </Button>
                    </div>
                    <Button
                        color="primary"
                        variant="contained"
                        className="submit-btn"
                        onClick={handleUpload}
                    >
                        Upload New File
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LinksForm;
